<template>
  <div class="flex items-center">
    <Menu
      as="div"
      class="relative ml-3"
    >
      <div>
        <MenuButton
          class="flex flex-row items-center justify-center space-x-1"
        >
          <ChevronDownIcon class="w-4 h-4" />
          <span class="text-sm text-gray-700 uppercase">{{ locale }}</span>
        </MenuButton>
      </div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems class="absolute right-0 z-10 mt-2 w-14 origin-center rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <MenuItem 
            v-for="(l, index) in locales"
            :key="index"
            v-slot="{ active }"
          >
            <span
              :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 text-center cursor-pointer uppercase']"
              @click="switchLanguage(l)"
            >
              {{ l }}
            </span>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

import { computed } from 'vue';
import { useI18n } from 'vue-i18n'
import Translation from '@/config/i18n/translations.js'
import { useRouter } from 'vue-router';

const { t, locale } = useI18n();

const locales = computed(() => {
  return Translation.supportedLocales.filter(l => l != locale.value);
});

const router = useRouter();

const switchLanguage = async (clickedLocale) => {
  const newLocale = clickedLocale;
  await Translation.switchLanguage(newLocale);
  try {
    await router.replace({ params: { locale: newLocale } })
  } catch (e) { 
    console.log(e)
    router.push("/")
  }
}

</script>
<template>
  <Listbox
    v-if="items?.length"
    v-model="selected"
    as="div"
    :disabled="disabled"
    multiple
  >
    <ListboxLabel :class="[required ? 'required' : '', 'block text-sm font-medium text-gray-700']">
      {{ label }}
    </ListboxLabel>
    <div class="relative mt-1">
      <ListboxButton
        :class="[errors.length ? errorClass : '', 'relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-main-500 focus:outline-none focus:ring-1 focus:ring-main-500 sm:text-sm']"
      >
        <span class="block truncate">
          {{ selected.length > 0 ? selected.join(', ') : $t('global.select') }}
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon 
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="(item, index) of items"
            :key="index"
            v-slot="{ active }"
            as="template"
            :value="item"
            @click="selectItem(item)"
          >
            <li
              :class="[active || isSelected(item) ? 'text-white bg-gray-500' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-8 pr-4']"
            >
              <span class="block truncate font-normal">
                {{ getLabelText(item) }}
              </span>

              <span 
                v-if="isSelected(item)"
                :class="'text-white absolute inset-y-0 left-0 flex items-center pl-1.5'"
              >
                <CheckIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </span>
              <span
                v-if="isSelected(item)"
                :class="'text-white absolute inset-y-0 right-0 flex items-center pr-1.5'"
                @click.stop="removeItem(item)"
              >
                <XMarkIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
    <p
      v-show="errors.length"
      class="mt-2 text-sm text-red-600"
    >
      {{ errors[0]?.$message }}
    </p>
  </Listbox>
</template>

<script setup>

import { ref, watch } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/vue/20/solid'

const errorClass = ref('border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500');

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: null
  },
  boxLabel: {
    type: String,
    default: null
  },
  items: {
    type: Array,
    default: () => []
  },
  translationKey: {
    type: String,
    default: null
  },
  disabled: Boolean,
  required: {
    type: Boolean,
    default: false
  },
  errors: {
    default: () => ([]),
    type: Array
  }
});

const selected = ref(props.modelValue || []);
const emits = defineEmits(['update:modelValue']);

const getLabelText = (item) => {
  if (typeof item === 'object') {
    return item.name;
  }
  return item;
}

const isSelected = (item) => {
  return selected.value.includes(item);
}

const selectItem = (item) => {
  if (!selected.value.includes(item)) {
    selected.value.push(item);
  }
};

const removeItem = (item) => {
  selected.value = selected.value.filter(i => i !== item);
};

watch(selected, () => {
  emits('update:modelValue', selected.value);
})

</script>
<template>
  <div class="grid grid-cols-2 gap-4">
    <div
      v-for="(field, fieldIndex) in formConfig.fields"
      v-show="showField(field)"
      :key="fieldIndex"
      :class="field.class"
    >
      <p
        v-if="field.type === 'info'"
      >
        {{ $t(field['text-key']) }}
      </p>
      <p
        v-if="field.type === 'multi-info'"
      >
        <span
          v-for="(item, index) in field.messages"
          :key="index"
        >
          <i18n-t
            v-if="item.slot"
            scope="global"
            :keypath="item.key"
            tag="div"
          >
            <b>{{ $t(item.slot) }}</b>
          </i18n-t>
          <div
            v-else
          >
            {{ $t(item.key) }}
          </div>
        </span>
      </p>
      <s-input
        v-if="field.type === 'text' || field.type === 'number'"
        v-model="v$[field.name].$model"
        :name="field.name"
        :type="field.type"
        :placeholder="$t(field.label || `label.${field.name}`)"
        :label="$t(field.label || `label.${field.name}`)"
        :errors="v$[field.name].$errors"
        :required="field.rules?.includes('required')"
      />

      <s-textarea 
        v-if="field.type === 'textarea'"
        v-model="v$[field.name].$model"
        :name="field.name"
        :placeholder="$t(`label.${field.name}`)"
        :label="$t(field.label || `label.${field.name}`)"
        :errors="v$[field.name].$errors"
        :required="field.rules?.includes('required')"
      />

      <div
        v-if="field.type === 'date'"
      >
        <label
          :for="field.name"
          :class="[field.rules?.includes('required') ? 'required' : '', 'block text-sm font-medium text-gray-700']"
          v-text="$t(`label.${field.name}`)"
        />
        <date-picker
          v-model="v$[field.name].$model"
          class="mt-1"
          locale="de"
          :name="field.name"
          :placeholder="$t(field.label || `label.${field.name}`)"
          :label="$t(field.label || `label.${field.name}`)"
          :select-text="$t('global.select')"
          :cancel-text="$t('global.cancel')"
          :enable-time-picker="field.enableTimePicker || false"
          :auto-apply="field.autoApply || true"
          :text-input="field.textInput || true"
          :format="field.format || 'dd.MM.yyyy'"
          :input-class-name="field.inputClassName ||
            v$[field.name].$errors.length ?
              'placeholder-red-300 date-picker-error text-sm date-picker focus:border-main-500 focus:border-2 focus:border-solid focus:ring-1 focus:ring-main-500' :
              'text-sm date-picker focus:border-main-500 focus:border-2 focus:border-solid focus:ring-1 focus:ring-main-500'"
          :position="field.position || 'left'"
        />
        <p
          v-show="v$[field.name].$errors.length"
          class="mt-2 text-sm text-red-600"
        >
          {{ v$[field.name].$errors[0]?.$message }}
        </p>
      </div>

      <div
        v-if="field.type === 'date-year-only'"
      >
        <label
          :for="field.name"
          :class="[field.rules?.includes('required') ? 'required' : '', 'block text-sm font-medium text-gray-700']"
          v-text="$t(`label.${field.name}`)"
        />
        <date-picker
          v-model="v$[field.name].$model"
          class="mt-1"
          locale="de"
          :name="field.name"
          :placeholder="$t(field.label || `label.${field.name}`)"
          :label="$t(field.label || `label.${field.name}`)"
          :select-text="$t('global.select')"
          :cancel-text="$t('global.cancel')"
          :enable-time-picker="field.enableTimePicker || false"
          :auto-apply="field.autoApply || true"
          :text-input="field.textInput || true"
          :format="field.format || 'dd.MM.yyyy'"
          :input-class-name="field.inputClassName || 
            v$[field.name].$errors.length ? 
              '' : 
              'text-sm date-picker focus:border-main-500 focus:border-2 focus:border-solid focus:ring-1 focus:ring-main-500'"
          :position="field.position || 'left'"
          year-picker
        />
        <p
          v-show="v$[field.name].$errors.length"
          class="mt-2 text-sm text-red-600"
        >
          {{ v$[field.name].$errors[0]?.$message }}
        </p>
      </div>

      <Dropdown
        v-if="field.type === 'dropdown'"
        v-model="v$[field.name].$model"
        :label="$t(field.label || `label.${field.name}`)"
        :box-label="$t(field.boxLabel || 'global.select')"
        :items="field.items"
        :errors="v$[field.name].$errors"
        :required="field.rules?.includes('required')"
      />

      <DropdownMultiple
        v-if="field.type === 'dropdown-multiple'"
        v-model="v$[field.name].$model"
        :label="$t(field.label || `label.${field.name}`)"
        :box-label="$t(field.boxLabel || 'global.select')"
        :items="field.items"
        :errors="v$[field.name].$errors"
        :required="field.rules?.includes('required')"
      />

      <Checkbox 
        v-if="field.type === 'checkbox'"
        v-model="v$[field.name].$model"
        :name="field.name"
        :label="$t(field.label || `label.${field.name}`)"
        :errors="v$[field.name].$errors"
      />

      <RadioGroup 
        v-if="field.type === 'radio'"
        v-model="v$[field.name].$model"
        :name="field.name"
        :label="$t(field.label || `label.${field.name}`)"
        :items="field.items"
        :required="field.rules?.includes('required')"
        :errors="v$[field.name].$errors"
      />

      <FileUpload
        v-if="field.type === 'file'"
        v-model="v$[field.name].$model"
        :name="field.name"
        :placeholder="$t(field.label || `label.${field.name}`)"
        :label="$t(field.label || `label.${field.name}`)"
        :label-type="field.labelType"
        :multiple="field.multiple || false"
        :show-files="field.showFiles || true"
        :required="field.rules?.includes('required')"
        :errors="v$[field.name].$errors"
        :accept="field.accept"
      />
    </div>
  </div>
</template>

<script setup>

import DatePicker from '@vuepic/vue-datepicker';
import SInput from '@/components/InputVue.vue';
import STextarea from '@/components/Textarea.vue';
import Dropdown from '@/components/DropdownVue.vue';
import DropdownMultiple from '@/components/DropdownMultiple.vue';
import Checkbox from '@/components/CheckboxVue.vue';
import FileUpload from '@/components/FileUpload.vue';
import RadioGroup from '@/components/RadioGroup.vue';
import { onBeforeRouteLeave } from 'vue-router';

import { required, requiredIf, email, regexAlpha, numeric, regexStreetNumber, regexPostalCode, sameAs } from '@/config/i18n/validators';

import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';
import { reactive, computed, ref, watch, onBeforeUnmount } from 'vue';

String.prototype.hashCode = function () {
  const seed = 0;
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < this.length; i++) {
    ch = this.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const store = useStore();
const fileData = ref(null);

const props = defineProps({
  formConfig: {
    type: Object,
    required: true
  },
  data: {
    type: Object,
    default: null
  },
  scoped: {
    type: Boolean,
    default: false
  }
});

const tmpState = {};
const tmpRules = {};

const getValidationRules = (field) => {
  const ruleNames = field.rules;
  const rules = {};

  ruleNames?.split(',').forEach((ruleName) => {
    if (ruleName === 'required') {
      rules['required'] = required;
    } else if (ruleName === 'email') {
      rules['email'] = email;
    } else if (ruleName === 'requiredIf') {
      rules['requiredIf'] = requiredIf(field);
    } else if (ruleName === 'regexAlpha') {
      rules['regexAlpha'] = regexAlpha;
    } else if (ruleName === 'numeric') {
      rules['numeric'] = numeric;
    } else if (ruleName === 'regexStreetNumber') {
      rules['regexStreetNumber'] = regexStreetNumber;
    } else if (ruleName === 'regexPostalCode') {
      rules['regexPostalCode'] = regexPostalCode;
    } else if (ruleName === 'regexIban') {
      rules['regexIban'] = regexIban;
    } else if (ruleName === 'sameAs') {
      rules['sameAs'] = sameAs(true);
    }
  });

  return rules;
};


for (const field of props.formConfig.fields) {
  if (field.type === 'info' || field.type === 'multi-info') continue;
  tmpRules[field.name] = getValidationRules(field);
  if (field.type === 'dropdown') {
    if (props.data && props.data[field.name]) {
      tmpState[field.name] = field.items.find(i => {
        if (typeof i === 'string' || i instanceof String) {
          return i === props.data[field.name];
        } else if (typeof i === 'object' || i instanceof Object) {
          return i.value === props.data[field.name];
        }
        return null;
      })
    }
  } else if (field.type === 'dropdown-multiple') {
    tmpState[field.name] = [];
  } else if (field.type === 'checkbox') {
    tmpState[field.name] = props.data ? props.data[field.name] : false;
  } else {
    tmpState[field.name] = props.data ? props.data[field.name] : null;
  }
}

const state = reactive(tmpState);
const rules = computed(() => (tmpRules));
const v$ = useVuelidate(rules, state);

const getData = () => {
  console.log(state);
  const data = Object.assign({}, state);

  Object.keys(data).forEach(key => {
    const config = props.formConfig.fields.find(item => (item.dependsOnValue !== 'any' && item.dependsOnField === key));

    if (config && data[config.dependsOnField] !== config.dependsOnValue) {
      delete data[config.name];
    }
    
    if (data[key] === 'false') {
      data[key] = false;
    } else if (data[key] === 'true') {
      data[key] = true;
    }
  });

  props.formConfig.fields.filter(item => item.ignore === true).forEach(item => {
    delete data[item.name];
  });

  // if (fileData.value?.length) {
  //   return Object.assign(data, {
  //     files: fileData.value
  //   });
  // }
  return data;
};

const hash = JSON.stringify(props.formConfig).hashCode();
// store.commit('registerForm', {
//   key: hash,
//   value: {
//     scoped: props.scoped,
//     form: v$
//   }
// });

const triggerFormValidFlag = () => {
  // if (!props.scoped) {
  //   if (!v$.value.$invalid) {
  //     store.commit('setFormValid');
  //   } else {
  //     store.commit('setFormInvalid');
  //   }
  // }
  // store.dispatch('updateFormValidity');
};

const showField = (field) => {
  if (field.dependsOnField) {
    if (field.dependsOnValue === 'any') {
      return v$.value[field.dependsOnField].$model !== null;
    }
    return v$.value[field.dependsOnField].$model === field.dependsOnValue;
  }
  return true;
};

triggerFormValidFlag();

const isValid = () => {
  return !v$.value.$invalid;
};

const touch = () => {
  return v$.value.$touch();
};

defineExpose({ getData, isValid, touch });

watch(v$, () => {
  triggerFormValidFlag();
});

onBeforeUnmount(() => {
  // store.commit('unregisterForm', hash);
});

onBeforeRouteLeave((to, from, next) => {
  // store.commit('unregisterForm', hash);
  next();
});

</script>

<style>
.required::after {
  content: "*";
}

.date-picker {
  border-color: #6b7280;
  font-size: 0.875rem;
}

.date-picker-error {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

</style>
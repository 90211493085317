<template>
  <div class="flex flex-col space-y-8 px-2 py-12">
    <div class="flex flex-row justify-center">
      <p 
        class="text-center text-gray-900 font-bold text-3xl"
      >
        {{ $t('label.headline') }}
      </p>
      
      <LanguageSwitcher
        class=""
      />
    </div>

    <FormCreator
      ref="form"
      form-config-file-name="moulay"
      class="mt-6"
    />

    <Button
      :text="$t('button.send')"
      :loading="loading"
      type="custom"
      @click.prevent="sendForm()"
    />

    <p 
      class="text-center text-gray-900 font-bold text-3xl"
    >
      {{ $t('label.headline2') }}
    </p>

    <div
      v-for="(item, index) of textParts"
      :key="index"
      class="text-center"
    >
      <p class="uppercase font-semibold text-red-500">
        {{ $t(item.header) }}
      </p>
      <div
        v-for="(children, childIndex) of item.children"
        :key="childIndex"
      >
        <p v-html="$t(children)" />
      </div>
    </div>

    <ModalVue 
      :show="showModal"
      @close="showModal = false"
    >
      <div class="flex flex-col mt-3 space-y-6">
        <div class="font-semibold text-xl text-center">
          {{ modalHeader }}
        </div>
        <div class="text-center">
          {{ modalText }}
        </div>
        <div class="flex justify-center">
          <div class="w-52">
            <Button
              :text="$t('button.confirm')"
              type="custom"
              @click.prevent="showModal = false"
            />
          </div>
        </div>
      </div>
    </ModalVue>
  </div>
</template>

<script setup>

import { ref } from 'vue'
import FormCreator from '@/components/FormCreator.vue'
import Button from '@/components/ButtonVue.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import BaseService from '@/services/base.service';
import ModalVue from '@/components/ModalVue.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const showModal = ref(false);
const form = ref(null);
const modalHeader = ref(null);
const modalText = ref(null);
const loading = ref(false);

const textParts = [{
  header: "header.1",
  children: ["text.1"]
}, {
  header: "header.2",
  children: ["text.2"]
}, {
  header: "header.3",
  children: ["text.3"]
}, {
  header: "header.4",
  children: ["text.4"]
}, {
  header: "header.5",
  children: ["text.5"]
}, {
  header: "header.6",
  children: ["text.6", "text.7", "text.8"]
}, {
  header: "header.7",
  children: ["text.9"]
}, {
  header: "header.8",
  children: ["text.10"]
}, {
  header: "header.9",
  children: ["text.11"]
}, {
  header: "header.10",
  children: ["text.12"]
}, {
  header: "header.11",
  children: ["text.13"]
}, {
  header: "header.12",
  children: ["text.14"]
}, {
  header: "header.13",
  children: ["text.15"]
}, {
  header: "header.14",
  children: ["text.16"]
}, {
  header: "header.15",
  children: ["text.17"]
  }];


const sendForm = () => { 
  form.value?.touch();
  loading.value = true;

  if (form.value?.isValid()) {
    BaseService
      .post('/api/mail', form.value.getData())
      .then(res => {
        modalHeader.value = t('label.success');
        modalText.value = t('label.send-data-success');
        showModal.value = true;
      })
      .catch(err => {
        modalHeader.value = t('label.error');
        modalText.value = t('label.send-data-error');

        if (err.response.status === 415) {
          modalText.value = err.response.data;
        }
        
        showModal.value = true;

        if (err.response.status !== 415) {
          BaseService
            .post('/api/mail-error');
        }
      })
      .finally(() => {
        loading.value = false;
      });
  } else {
    loading.value = false;
    modalHeader.value = t('label.error');
    modalText.value = t('label.form-error');
    showModal.value = true;
  }
}

</script>
<template>
  <div class="flex flex-col space-y-8 px-4 sm:w-2/3 sm:mx-auto pb-4">
    <div class="mx-auto">
      <div class="flex flex-row justify-between">
        <img class="h-32 sm:h-48" src="/wcm_logo.jpg">
        <img class="h-32 sm:h-48" src="/logo_centre_nautique.jpg">
      </div>
    </div>
    <div class="flex flex-row justify-center">
      <p 
        class="text-center text-gray-900 font-bold text-3xl"
      >
        {{ $t('privacy.headline') }}
      </p>
      <LanguageSwitcher
        class=""
      />
    </div>

    <FormCreator
      ref="form"
      form-config-file-name="privacy"
      class="mt-6"
    />

    <div class="mx-auto w-2/3">
      <Button
        :text="$t('button.send')"
        :loading="loading"
        type="custom"
        @click.prevent="sendForm()"
      />
    </div>

    <ModalVue 
      :show="showModal"
      @close="showModal = false"
    >
      <div class="flex flex-col mt-3 space-y-6">
        <div class="font-semibold text-xl text-center">
          {{ modalHeader }}
        </div>
        <div class="text-center">
          {{ modalText }}
        </div>
        <div class="flex justify-center">
          <div class="w-52">
            <Button
              :text="$t('button.confirm')"
              type="custom"
              @click.prevent="showModal = false"
            />
          </div>
        </div>
      </div>
    </ModalVue>
  </div>
</template>

<script setup>

import { ref } from 'vue'
import FormCreator from '@/components/FormCreator.vue'
import Button from '@/components/ButtonVue.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import BaseService from '@/services/base.service';
import ModalVue from '@/components/ModalVue.vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const route = useRoute();
const { t } = useI18n();
const showModal = ref(false);
const form = ref(null);
const modalHeader = ref(null);
const modalText = ref(null);
const loading = ref(false);

const textParts = [{
  header: "header.1",
  children: ["text.1"]
}, {
  header: "header.2",
  children: ["text.2"]
}, {
  header: "header.3",
  children: ["text.3"]
}, {
  header: "header.4",
  children: ["text.4"]
}, {
  header: "header.5",
  children: ["text.5"]
}, {
  header: "header.6",
  children: ["text.6", "text.7", "text.8"]
}, {
  header: "header.7",
  children: ["text.9"]
}, {
  header: "header.8",
  children: ["text.10"]
}, {
  header: "header.9",
  children: ["text.11"]
}, {
  header: "header.10",
  children: ["text.12"]
}, {
  header: "header.11",
  children: ["text.13"]
}, {
  header: "header.12",
  children: ["text.14"]
}, {
  header: "header.13",
  children: ["text.15"]
}, {
  header: "header.14",
  children: ["text.16"]
}, {
  header: "header.15",
  children: ["text.17"]
  }];


const sendForm = () => { 
  form.value?.touch();
  loading.value = true;

  if (form.value?.isValid()) {
    const data = Object.assign({}, { uuid: route.params.uuid }, form.value.getData());
    
    BaseService
      .post('/api/privacy', data)
      .then(res => {
        modalHeader.value = t('label.success');
        modalText.value = t('privacy.send-data-success');
        showModal.value = true;
      })
      .catch(err => {
        modalHeader.value = t('label.error');
        modalText.value = t('label.send-data-error');
      })
      .finally(() => {
        loading.value = false;
      });
  } else {
    loading.value = false;
    modalHeader.value = t('label.error');
    modalText.value = t('label.form-error');
    showModal.value = true;
  }
}

</script>
<template>
  <button
    :class="[buttonSize, buttonType, 'flex w-full justify-center items-center rounded-md border border-transparent font-medium shadow-sm focus:outline-none uppercase cursor-pointer disabled:opacity-50']"
    :disabled="loading || disabled"
  >
    <div class="flex">
      <spinner
        v-if="loading"
        class="mr-2"
        height="h-5"
        width="w-5"
      />
      <slot>{{ text }}</slot>
    </div>
  </button>
</template>

<script setup>

import { computed } from 'vue';
import Spinner from "@/components/Spinner";

const props = defineProps({
  text: {
    type: String,
    default: null
  }, 
  size: {
    type: String,
    default: 'md'
  },
  type: {
    type: String,
    default: 'primary'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const buttonSize = computed(() => { 
  switch (props.size) {
    case 'xs':
      return 'px-2.5 py-1.5 text-xs';
    case 'sm':
      return 'px-3 py-2 text-sm';
    case 'md':
      return 'px-4 py-2 text-sm';
    case 'lg':
      return 'px-4 py-2 text-base';
    case 'xl':
      return 'px-6 py-3 text-base';
    default:
      return 'px-2.5 py-1.5 text-xs';
  }
});

const buttonType = computed(() => { 
  switch (props.type) {
    case 'primary':
      return 'bg-indigo-500 text-white hover:bg-indigo-600';
    case 'secondary':
      return 'bg-white hover:bg-gray-100 text-main-500 shadow-lg';
    case 'soft':
      return 'bg-indigo-50 text-indigo-600 hover:bg-indigo-100 focus:ring-indigo-500';
    case 'danger':
      return 'bg-red-600 text-white hover:bg-red-500';
    case 'danger-secondary':
      return 'bg-white text-red-600 hover:bg-red-100';
    case 'custom':
      return 'custom-red custom-red:hover text-white';
    default:
      return 'bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500';
  }
});

</script>

<style scoped>

.custom-red {
  background-color: #d12e3e;
}

.custom-red:hover {
  background-color: #d01e30;
}

</style>